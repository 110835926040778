import { Link } from "gatsby"
import React from "react"
import './footer.css'

const Footer = ({props}) => (
  <>
    <footer className={"footer navbar-expand-lg bg-dark"}>
{/*    <section id="info">
        <div>© {new Date().getFullYear()}, Logicom Instruments Pte Ltd</div>
      </section> */}
      <section id="legal"><div>© {new Date().getFullYear()}, Logicom Instruments Pte Ltd</div></section>
    </footer>
  </>
)

export default Footer
