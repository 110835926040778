/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"
import Header from "../components/logicom-header/header"
import Footer from "../components/logicom-footer/footer"
import "./layout.css"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css'
// import {Helmet} from "react-helmet"
// import Holder from 'holderjs'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/holder/2.9.7/holder.min.js";
    script.async = true;
    document.head.appendChild(script);
  return () => {
      document.head.removeChild(script);
    }
  }, []);
  return (
    <>
      <Container id="body" fluid>
        <Row id="header"><Header/></Row>
        <Row id="content">
          <Col>
            <main>{children}</main>
          </Col>
        </Row>
        <Row id="footer"><Footer/></Row>  
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
