import { Link } from "gatsby"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import LogicomLogo from '../logicom-images/LogicomLogo'
import './header.css'

const Header = ({}) => (
    <Navbar variant="light" bg="light" expand="lg">
        <LogicomLogo/><br/>
        <Navbar.Brand>Logicom Instruments</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
                <Nav.Link as={Link} to={`/`}>Home</Nav.Link>
                {/* <Nav.Link as={Link} to={`#products-and-services`}>Products & Services</Nav.Link> */}
                <Dropdown as={Nav.Item} navbar={true}>
                    <Dropdown.Toggle as={Nav.Link}>
                        Products & Services
                    </Dropdown.Toggle>
                    <Dropdown.Menu id="basic-nav-dropdown">
                    <Dropdown.Item as={Link} to={`/product-services/professional-svcs`}>Professional Services</Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/product-services/temperature-humidity`}>Temperature & Humidity Test System</Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/product-services/halt-hass`}>Accelerated Stress Test System</Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/product-services/thermal-shock`}>Thermal Shock Test System</Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/product-services/vibration`}>Vibration Test System</Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/product-services/agree`}>AGREE Test System</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Nav.Link as={Link} to={`/thermotron/`}>Thermotron</Nav.Link>
                {/* <Link className={`nav-link`} to={`#contact-us`}>Contact Us</Link> */}
                <Nav.Link as={Link} to={`/contact-us/`}>Contact Us</Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
)

export default Header